<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-3">
              <v-toolbar color="orange lighten-2">
                <v-toolbar-title>Riggott IMS - Request Password</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    prepend-icon="mdi-account"
                    name="email"
                    label="Email"
                    type="text"
                    v-model="email"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="orange lighten-2"
                  @click="sendLink">
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar
        v-model="showMessage"
          :timeout="5000"
      >
        {{ message }}
        <template v-slot:action="{ attrs }">
        <v-btn
          class="text-h5"
          color="grey darken-2"
          text
          v-bind="attrs"
          @click="showMessage = false"
        >
          Close
        </v-btn>
      </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>
  
<script>
import axios from '../../axios';

export default {
  name: 'RequestPasswordReset',
  data() {
    return {
      email: '',
      showMessage: false,
      message: '',
    };
  },
  methods: {
    sendLink() {
      const postData = {};
      postData.email = this.email;
      axios.post('/users/requestPasswordReset.json', postData)
      .then((response) => {
        this.showMessage = true;
        this.message = response.data.message;
      });
    }
  },
};
</script>
